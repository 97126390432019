import i18n from '@/i18n';
import { NavigationGuardNext, Route } from 'vue-router';
import { ResponseError } from '@/models/responseError';
import { AuthResponse } from '@/models/authResponse';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';
import { TokenResponse } from '@/models/tokenResponse';
import { EventBus } from '@/services/event-bus';
import merge from 'lodash/merge';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { AuthAccess } from '@/models/authAccess';
import { Store } from 'vuex';
import { RootState } from '@/models/rootState';
import { ApiLanguage } from '@/models/language';
import { getApiUrl } from '@/consts';

const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
const parseTokenSubject = (token: string): AuthAccess => {
  const parsedToken = parseJwt(token);
  return JSON.parse(parsedToken.sub) as AuthAccess;
};
const getRefreshTokenExpiry = (refreshToken: string | null): number => {
  // Return refresh token expiry in seconds
  if (refreshToken) {
    const parsedToken = parseJwt(refreshToken);
    const expiry = JSON.parse(parsedToken.exp);
    return expiry * 1000;
  }
  return 0;
};

const retrieveToken = () => localStorage.getItem('token');
const retrieveRefreshToken = () => localStorage.getItem('refresh_token');

const getBaseUrl = (): string => {
  return getApiUrl() + '/' + process.env.VUE_APP_API_VERSION;
};

const retrieveJsonKey = (key: string) => {
  try {
    const str = localStorage.getItem(key);
    return str ? JSON.parse(str) : null;
  } catch {
    return null;
  }
};
const retrieveClubs = () => {
  return retrieveJsonKey('clubs');
};
const retrieveLanguage = () => {
  return retrieveJsonKey('language');
};
const saveJsonToLocalStorage = (name: string, data: Array<string>) => {
  localStorage.setItem(name, JSON.stringify(data));
};
const saveLanguageToLocalStorage = (language: ApiLanguage) => {
  localStorage.setItem('language', JSON.stringify(language));
};

const sendTokenToServiceWorker = (refreshToken: string) => {
  // Send the new tokens to the service worker to be used when replaying
  if (navigator instanceof Navigator && navigator.serviceWorker !== undefined && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'setToken',
      refresh_token: refreshToken,
    });
  }
};

const saveNewTokenResponse = (tokenResponse: TokenResponse): [AuthAccess, number] => {
  localStorage.setItem('token', tokenResponse.token);
  localStorage.setItem('refresh_token', tokenResponse.refresh_token);
  sendTokenToServiceWorker(tokenResponse.refresh_token);

  if (tokenResponse.clubs) {
    saveJsonToLocalStorage('clubs', tokenResponse.clubs);
  }
  if (tokenResponse.club_users) {
    // Users who can be switched to for each club
    saveJsonToLocalStorage('clubUsers', tokenResponse.club_users);
  }
  const data = parseTokenSubject(tokenResponse.token);
  const refreshTokenExpiry = getRefreshTokenExpiry(tokenResponse.refresh_token);

  localStorage.setItem('loginTs', Date.now().toString());

  // So we can know the originating user if they switch user with a pin code
  localStorage.setItem('origUserUuid', data.user_uuid);
  saveLanguageToLocalStorage(data.language);

  return [data, refreshTokenExpiry];
};

const getClubUsers = () => {
  const clubUsers = retrieveJsonKey('clubUsers');
  if (!clubUsers) {
    return [];
  }
  return clubUsers;
};

const clearLocalStorage = () => {
  const items = ['token', 'refresh_token', 'clubs', 'features', 'clubUsers', 'origUserUuid', 'loginTs'];
  for (const item of items) {
    localStorage.removeItem(item);
  }
};
const appTypeToAuthType = (appId: string): string => {
  const appTypetoAuthType = new Map();
  appTypetoAuthType.set('coach', 'coach');
  appTypetoAuthType.set('scheme', 'ngo');
  appTypetoAuthType.set('club', 'admin');
  appTypetoAuthType.set('parent', 'parent');
  return appTypetoAuthType.get(appId);
};
const shouldRefreshToken = (error: ResponseError): boolean => {
  return !!(error.response && error.response.status && error.response.status === 401);
};
const getValidRefreshToken = (): null | string => {
  // if they have a refresh token then it will get them a new access token
  // so is more important to check this than the shortlived access token
  const refreshToken = retrieveRefreshToken();
  return getRefreshTokenExpiry(refreshToken) < Date.now() ? null : refreshToken;
};
const resetPassword = async (email: string, clientId: string): Promise<boolean> => {
  try {
    const url = `${getBaseUrl()}/auth/reset-password`;
    const resp: AuthResponse = await fetchJSON(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, client_id: clientId }),
    });
    return resp.response.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
};
// Make a request with the token.
const fetchJSONWithToken = async (url: string, options: object = {}): Promise<AuthResponse> => {
  const token = retrieveToken();
  let optionsWithToken = options;
  if (token === null) {
    EventBus.$emit('invalid-token');
    return Promise.reject(Error('Token missing'));
  } else {
    optionsWithToken = merge({}, options, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
  return fetchJSON(url, optionsWithToken);
};

const fetchNewToken = async (refreshToken: string) => {
  const url = getBaseUrl() + '/auth/refresh';
  const response: AuthResponse = await fetchJSON(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });
  return response.body as { token: string };
};

// Call to refresh the token.
const refreshToken = async (): Promise<void> => {
  const refreshToken = getValidRefreshToken();
  if (refreshToken) {
    try {
      const responseBody = await fetchNewToken(refreshToken);
      localStorage.setItem('token', responseBody.token);
      EventBus.$emit('update-token', responseBody.token);
      return Promise.resolve();
    } catch (error) {
      console.error('Error refreshing access token: ', error);
    }
  }
  EventBus.$emit('invalid-token');
  return Promise.reject(Error('Invalid refresh token'));
};

const authFetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});
const getErrorDetail = (authResponse: AuthResponse) => {
  const body = (authResponse.body || {}) as ErrorIdentity;
  return body.error ? body.detail : 'Failed';
};
const showAdvanced = () => {
  return localStorage.getItem('show_advanced') !== null;
};
const pathDisabled = (to: Route, store: Store<RootState>) => {
  if (to.path === '/users' && store.getters.appId === 'club') {
    // the user page is only accessible in clubportal if you have a fully-local club
    return store.getters['auth/loggedInClubHasRemoteMembers'];
  }
  if (to.path === '/tasks' || (to.path === '/members' && store.getters.appId === 'scheme')) {
    // This is set manually if you wish to show advanced functionality for support users.
    return !showAdvanced();
  }
  if (store.getters.appId === 'scheme' && (to.path === '/clubs' || to.path === '/users')) {
    // Hide Clubs and Users for coursepro customers
    return store.getters['auth/authProvider'] === 'coursepro';
  }
  if (to.path === '/switch-user') {
    // Only for coursepro users who have permission
    const clubUsers = getClubUsers();
    return store.getters['auth/authProvider'] !== 'coursepro' || clubUsers.length === 0;
  }
  return false;
};

configureRefreshFetch({
  // Pass fetch function you want to wrap, it should already be adding
  // token to the request
  fetch,
  // shouldRefreshToken is called when API fetch fails and it should decide
  // whether the response error means we need to refresh token
  shouldRefreshToken: () => false,
  // refreshToken should call the refresh token API, save the refreshed
  // token and return promise -- resolving it when everything goes fine,
  // rejecting it when refreshing fails for some reason
  refreshToken: () => Promise.resolve(),
});

const isProtectedPath = (path: string) => {
  const unprotectedPaths = ['/login', '/logout', '/redirect', '/set-password', '/reset-password', '/unsubscribe'];
  return unprotectedPaths.includes(path) === false;
};

const authGuard = (store: Store<RootState>) => (to: Route, from: Route, next: NavigationGuardNext) => {
  window.scrollTo(0, 0);
  const refreshToken = getValidRefreshToken();
  if (isProtectedPath(to.path) && refreshToken === null) {
    next({ name: 'login', query: { logout: '1', returnUrl: to.fullPath } });
  } else if (pathDisabled(to, store)) {
    next('/'); // back to home page
  } else if (from.path === '/switch-user' && !to.query.switch && to.path !== '/login') {
    next({ name: 'switch-user' });
  } else {
    next();
  }
};

const getLoginErrorString = (err: ResponseError): string => {
  // detail can be ERROR_FETCH (couldn't connect to bgapi API), ERROR_INVALID_EMAIL, UNKNOWN_ERROR,
  // or one of the errors listed in BGAPI's AuthProvider:checkCredentials.
  const key = `login.errors.${err.body.detail}`;
  if (i18n.te(key)) {
    return i18n.t('login.errors.failed', { detail: i18n.t(key) });
  }
  return i18n.t('login.errors.unknown', { detail: err.body.detail });
};

export {
  authGuard,
  isProtectedPath,
  pathDisabled,
  getErrorDetail,
  authFetch,
  fetchJSON,
  resetPassword,
  parseTokenSubject,
  parseJwt,
  getRefreshTokenExpiry,
  appTypeToAuthType,
  retrieveToken,
  retrieveRefreshToken,
  retrieveClubs,
  retrieveLanguage,
  clearLocalStorage,
  getBaseUrl,
  saveLanguageToLocalStorage,
  retrieveJsonKey,
  saveJsonToLocalStorage,
  getClubUsers,
  saveNewTokenResponse,
  getValidRefreshToken,
  getLoginErrorString,
  fetchNewToken,
  sendTokenToServiceWorker,
  refreshToken,
  showAdvanced,
};
