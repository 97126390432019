
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Online } from '@/components/mixins/online';
import { Club } from '@/models/club';
import { EventBus } from '@/services/event-bus';
import { pathDisabled } from '@/services/auth';
import { AppIds, getApiUrl } from '@/consts';
import { getUserClubs } from '@/services/utils';
import { PageDefinition } from '@/models/pageDefinition';
import { ApiLanguage, Language } from '@/models/language';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';

const languageCodes: Record<string, string> = {
  'en-GB': 'English',
  'cy-GB': 'Cymraeg',
  'fr-FR': 'Français',
  'de-DE': 'Deutsch',
};

const pages = {
  club: [
    { name: 'members', icon: 'mdi-account-multiple', text: 'nav.members' },
    { name: 'groups', icon: 'mdi-account-group', text: 'nav.groups' },
    { name: 'coaches', icon: 'mdi-school', text: 'nav.coaches' },
    { name: 'reports-menu', icon: 'mdi-file-chart', text: 'nav.reports' },
    // { name: 'awards-email', icon: 'mdi-email-edit', text: 'nav.awards_email' },
    { name: 'users', icon: 'mdi-account-supervisor-circle', text: 'nav.users' },
    { name: 'about', icon: 'mdi-help-circle-outline', text: 'nav.about' },
  ],
  coach: [
    { name: 'member-groups', icon: 'mdi-account-multiple', text: 'nav.members' },
    { name: 'planning', icon: 'mdi-clipboard-text', text: 'nav.planning' },
    { name: 'scheme', icon: 'mdi-book-open-variant', text: 'nav.scheme' },
    { name: 'offline', icon: 'mdi-wifi-off', text: 'nav.offline' },
    { name: 'about', icon: 'mdi-help-circle-outline', text: 'nav.about' },
    { name: 'switch-user', icon: 'mdi-account-switch', text: 'nav.switch_user' },
  ],
  scheme: [
    { name: 'overview', icon: 'mdi-file-table-box-multiple', text: 'nav.overview' },
    { name: 'tags', icon: 'mdi-tag-text', text: 'nav.tags' },
    { name: 'users', icon: 'mdi-account-supervisor-circle', text: 'nav.users' },
    { name: 'members', icon: 'mdi-account-multiple', text: 'nav.members' },
    { name: 'clubs', icon: 'mdi-home-city', text: 'nav.clubs' },
    { name: 'tasks', icon: 'mdi-calendar-clock', text: 'nav.tasks' },
    { name: 'reports-menu', icon: 'mdi-file-chart', text: 'nav.reports' },
    { name: 'about', icon: 'mdi-help-circle-outline', text: 'nav.about' },
  ],
  parent: [
    { name: 'overview', icon: 'mdi-file-table-box-multiple', text: 'nav.overview' },
    { name: 'account', icon: 'mdi-account-cog', text: 'nav.account' },
    { name: 'about', icon: 'mdi-help-circle-outline', text: 'nav.about' },
  ],
};

@Component
export default class NavigationComponent extends mixins(Online) {
  get clubId() {
    return this.$store.getters['auth/loggedInClubId'];
  }

  set clubId(clubId: number) {
    this.changeClub(clubId);
  }

  get clientId() {
    return this.$store.getters['auth/clientId'];
  }

  get isOrigUser() {
    return this.$store.getters['auth/isOrigUser']();
  }

  get canEditUser() {
    return this.$store.getters['auth/isFeatureEnabled']('CAN_EDIT_SELF') && this.isOrigUser;
  }

  getApiUrl() {
    return getApiUrl();
  }

  async editUser() {
    const response: AuthResponse = await makeRequest('GET', '/auth/edit-url');
    const url = response.body.url;
    if (!url) {
      this.$alert('error', this.$t('alerts.cannot_edit_user'));
    } else {
      window.open(url, '_blank');
    }
  }

  async changeClub(clubId: number) {
    await this.$store.dispatch('auth/changeClub', clubId);
    try {
      await this.$router.push('/'); // return home, since we may be viewing an item that does not belong to this club.
    } catch {
      // Ignore navigation errors when already on home
    }
  }

  get languageId() {
    let languageId = this.$store.getters['auth/languageId'];
    // When not logged in we get the languageId from the currentLocale
    if (!languageId) {
      const currentLocale = this.$store.getters['auth/currentLocale'];
      const language = this.languages.find((l: Language) => l.language.split('-')[0] === currentLocale);
      if (language) {
        languageId = language.id;
      }
    }
    return languageId;
  }

  set languageId(languageId: number) {
    if (this.isLoggedIn) {
      this.$store.dispatch('auth/changeLanguage', languageId);
    } else {
      const language = this.languages.find((l: Language) => l.id === languageId);
      this.$store.dispatch('auth/saveLanguage', language);
    }
  }

  async created(): Promise<void> {
    EventBus.$on('user-initialized', () => {
      if (pathDisabled(this.$router.currentRoute, this.$store)) {
        // reload the current path to ensure the auth-guard picks up that we need to navigate the user away.
        this.$router.go(0);
      }
    });
  }

  resetOverview(pageName: string): void {
    if (pageName === 'overview') {
      EventBus.$emit('reset');
    }
  }

  filterPages(page: PageDefinition): boolean {
    const { route } = this.$router.resolve({ name: page.name });
    return !pathDisabled(route, this.$store);
  }

  async logout(): Promise<void> {
    if (this.isDeviceOnline) {
      await this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    }
  }

  get pages(): Array<PageDefinition> {
    return pages[this.appId].filter(this.filterPages);
  }

  get languages(): Array<Language> {
    let languages = [];
    if (!this.isLoggedIn) {
      languages = this.$store.state.auth.languages;
    } else if (this.loggedInClub && this.loggedInClub.languages?.length) {
      languages = this.loggedInClub.languages;
    }
    return languages.map((l: ApiLanguage) => ({
      id: l.id,
      language: l.language,
      name: languageCodes[l.language],
    }));
  }

  get clubs(): Array<Club> {
    return this.$store.getters['clubs/clubs'];
  }

  get userClubsIds() {
    return this.$store.getters['auth/clubIds'];
  }

  get loggedInClub() {
    return this.clubs.find(c => this.clubId === c.id);
  }

  get userClubs() {
    return getUserClubs(this.clubs, this.userClubsIds);
  }

  get appId() {
    return this.$store.getters.appId as AppIds;
  }

  get isLoggedIn() {
    return this.$store.getters['auth/isLoggedIn']();
  }

  get loggedInUserName() {
    return this.$store.getters['auth/loggedInUserName'];
  }

  get isDeviceOnline() {
    return this.online;
  }
}
