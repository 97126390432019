import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'Online',
})
class Online extends Vue {
  public online = navigator.onLine;

  mounted() {
    window.addEventListener('online', () => {
      this.online = true;
    });
    window.addEventListener('offline', () => {
      this.online = false;
    });
  }
}

export { Online };
