import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import { merge } from 'lodash';
import LocaleMessages = VueI18n.LocaleMessages;

/**
 * Gets a file if it exists to override default translations
 *
 * @param locale
 */
const getTranslationOverrides = async (
  locale: string | undefined,
  translationUrl: string
): Promise<LocaleMessageObject> => {
  if (!locale) {
    locale = process.env.VUE_APP_I18N_LOCALE || 'en';
  }
  if (!translationUrl) {
    return Promise.resolve({});
  }

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Origin', window.location.hostname);

  // Remove trailing slash
  let url = translationUrl;
  if (url.charAt(url.length - 1) === '/') {
    url = url.substring(0, url.lastIndexOf('/'));
  }

  const response = await fetch(`${url}/${locale}.json`, {
    method: 'GET',
    headers: headers,
  });

  if (!response.ok) {
    const message = `Error fetching ${url}: ${response.statusText} - if no file expected then that is OK`;
    console.log(message);
    return Promise.resolve({}); // if no file found then its OK app will run without additional translations
  } else {
    return (await response.json()) as LocaleMessageObject;
  }
};

/**
 * Provided with existing localeMessages for selected locale
 * and combines them with only the ones that need overriding
 *
 * @param locale
 * @param localeMessages
 * @return Promise<LocaleMessages> combined localeMessages
 */
const overrideDefaultTranslations = async (
  locale: string,
  localeMessages: LocaleMessages,
  translationUrl: string
): Promise<LocaleMessageObject> => {
  let overrides = {} as LocaleMessageObject;
  try {
    overrides = await getTranslationOverrides(locale, translationUrl); // returns an empty object if there's not one
  } catch (error) {
    console.warn(
      'Error fetching translation is only a problem if expecting an additional override remote en.json file'
    );
  }
  const existing = localeMessages[locale];
  return merge(existing, overrides); // overrides will be empty object if fetch fails above
};

export { getTranslationOverrides, overrideDefaultTranslations };
