import { Video } from './video';

export enum NodeType {
  Level = 'l',
  SkillGroup = 'sg',
  Skill = 's',
}

export type DependencyArray = [Array<string>, number];

export interface NodeLink {
  is_owner: number;
  slug: string;
  scheme_uuid: string;
}

export interface SchemeNodeFiles {
  filename: string;
  title: string;
  description: string;
  location: string;
}

export enum ContentType {
  Title = 'title',
  Text = 'text',
  Warning = 'warning',
  Tags = 'tags',
  Files = 'files',
  Video = 'video',
  Link = 'link',
}

export interface SchemeNodeContent {
  id: number;
  title: string;
  type: ContentType;
  value: string | string[] | Array<SchemeNodeFiles>;
}

export interface SchemeNodeModel {
  id: string;
  children: Array<SchemeNodeModel>;
  hidden: boolean;
  completion: DependencyArray | null;
  dependencies: DependencyArray | [] | null;
  content: Array<SchemeNodeContent>;
  title: string;
  skipped?: boolean;
  parent?: string;
  self?: string;
  parentId?: string;
  t?: NodeType;
  assessment?: Array<number>; // Three numbers [min, max, pass]  e.g [0, 5, 3] would be from 0 to 5 and 3 is a pass.
  group?: string; // scheme group
  videos?: Video[]; // video urls added by frontend first time its fetched
  links?: NodeLink[];
  is_owner?: number;
}
