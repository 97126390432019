import i18n from '@/i18n';
import { SchemeNodeModel } from '@/models/schemeNodeModel';
import { TreeViewItem } from '@/models/tree-view-item';
import { isManualButtonId, isSkill } from './utils';

const allNodes = (tree: SchemeNodeModel) => {
  if (!tree) {
    return [];
  }
  const queue = [];
  const allNodes = [] as SchemeNodeModel[];
  queue.push(tree);
  while (queue.length > 0) {
    const currNode = queue.shift() as SchemeNodeModel;
    if (currNode.children) {
      currNode?.children.forEach((childNode: SchemeNodeModel) => {
        allNodes.push(childNode);
        queue.push(childNode);
      });
    }
  }

  return allNodes.map((node: SchemeNodeModel) => {
    return {
      id: node.id,
      name: node.title,
      skill: !!node.assessment,
      parentId: node.parentId,
    };
  });
};

const schemeNodeModelToTreeNode = (
  schemeNodeModel: SchemeNodeModel,
  position: [number, number] = [0, 1],
  slug = '',
  multiScheme = false,
  parentIsMirror = false
) => {
  if (isManualButtonId(schemeNodeModel.id)) {
    // we do not show these
    return [];
  }
  let title = schemeNodeModel.title || schemeNodeModel.id;
  if (schemeNodeModel.id === '/' && !multiScheme) {
    // If this is a single-scheme tree, we show 'Expand' for the root node, not the scheme title.
    title = i18n.t('overview.expand_tree') as string;
  }
  // if we have a slug, prefix IDs with this so we can differenciate node IDs from different schemes within a same tree.
  const len = schemeNodeModel.children ? schemeNodeModel.children.length : 0;
  const mirrored = !schemeNodeModel.is_owner;
  const children = schemeNodeModel.children
    ?.map((n, i) => schemeNodeModelToTreeNode(n, [i, len], slug, multiScheme, mirrored))
    .flat();

  if (!children.length && !isSkill(schemeNodeModel)) {
    children.push({
      id: `drop-${schemeNodeModel.id}`,
      name: '',
      scheme: slug,
      children: [],
      skill: true,
      hidden: false,
      skipped: false,
      mirrored: false,
      mirrorRoot: false,
      position: [0, 1],
    } as TreeViewItem);
  }

  const treeViewItem: TreeViewItem = {
    id: (slug ? `${slug}:` : '') + schemeNodeModel.id,
    name: title,
    scheme: slug,
    children,
    skill: !!schemeNodeModel.assessment,
    hidden: schemeNodeModel.hidden,
    skipped: schemeNodeModel.skipped,
    mirrored,
    mirrorRoot: !parentIsMirror && mirrored && children.every(c => c.mirrored && c.scheme === slug),
    position,
  };
  // we consider a branch to be a mirrored branch if every child is also mirrored from the same scheme. These branches
  // will be locked to editing and can only be re-mirrored at the mirrorRoot.

  return [treeViewItem];
};

// https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VTreeview/util/filterTreeItems.ts
// Should be able to rewrite the search so that v-treeview just uses a filter function
const filterTreeItem = (item: TreeViewItem, search: string): boolean => {
  return item.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
};

const getDescription = (schemeNodeModel: SchemeNodeModel) => {
  const content = schemeNodeModel.content?.filter(c => c.title === 'Description');
  return content && content.length ? (content[0].value as string) : '';
};

const purgeInvalidLinks = (markdown: string, schemeSlug: string, nodeIds: string[]) => {
  const links = markdown.matchAll(/\[(.+?)\]\((.+?)\)/g);
  let fixed = markdown;
  for (const [link, title, nodeId] of links) {
    // Links beginning with '/' are assumed to be node links
    // We ignore any external links (http(s)://)
    if (!nodeId.match(/^https?:/)) {
      if (!nodeIds.includes(nodeId)) {
        // remove the link, replacing with just the title
        fixed = fixed.replace(link, title);
      } else {
        // prefix with the slug
        const fixedLink = link.replace(nodeId, `/scheme/${schemeSlug}${nodeId}`);
        fixed = fixed.replace(link, fixedLink);
      }
    }
  }
  return fixed;
};

// split into an array and remove the 'kaltura:' type prefix
const extractVideoIds = (videoList: string): string[] =>
  videoList
    .split(',')
    .map(id => id.split(':')[1])
    .filter(id => id && id !== 'null');
// encode as csv and prepend kaltura: prefix
const formatVideoIds = (videoIds: Array<any>): string =>
  videoIds.map((id: any) => (typeof id === 'string' ? `kaltura:${id}` : '')).join(',');

const generateNewNodeId = (parentNode: SchemeNodeModel, title: string) => {
  title = title
    .toLowerCase()
    .replace(/[^0-9a-z-]/g, '-')
    .replace(/-+/g, '-')
    .trim()
    .substring(0, 15);
  if (title.charAt(title.length - 1) === '-') {
    title = title.substring(0, title.length - 1);
  }

  let newId = '';
  if (parentNode.id === '/') {
    newId = `${parentNode.id}${title}`;
  } else {
    newId = `${parentNode.id}/${title}`;
  }
  newId = newId.substring(0, 97);

  let exists;
  let addNum = false;
  let num = 2;

  do {
    // Check original id then if exists, get the next number that doesn't exist
    const id = num === 2 ? newId : `${newId}-${num}`;
    exists = parentNode.children.find((node: SchemeNodeModel) => {
      return node.id === id;
    });
    if (exists) {
      addNum = true;
      num += 1;
    }
  } while (exists);

  return addNum ? `${newId}-${num}` : newId;
};

export {
  filterTreeItem,
  schemeNodeModelToTreeNode,
  getDescription,
  allNodes,
  purgeInvalidLinks,
  extractVideoIds,
  formatVideoIds,
  generateNewNodeId,
};
