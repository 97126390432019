import { authFetch, fetchJSON, getBaseUrl, retrieveToken } from '@/services/auth';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { AuthResponse } from '@/models/authResponse';

let lastOnlineCheck = 0;

const getLastOnlineCheck = () => {
  return lastOnlineCheck;
};

const setLastOnlineCheck = (v: number) => {
  lastOnlineCheck = v;
};

const getErrorDetail = (resp: any) => {
  const body = (resp.body || {}) as ErrorIdentity;
  if (body.error) {
    return body.detail;
  } else if (resp.response && resp.response.statusText) {
    return resp.response.statusText;
  }
  return 'Request failed';
};

const notifyOffline = () => {
  window.dispatchEvent(new Event('offline'));
};

const notifyOnline = () => {
  window.dispatchEvent(new Event('online'));
};

const makeRequest = async (
  method: 'GET' | 'PATCH' | 'DELETE' | 'PUT' | 'POST',
  url: string,
  options?: object,
  withAuth = true
): Promise<AuthResponse> => {
  // Add a unique request id header to each request so we know if the response has come from cache
  const requestId =
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substring(2);
  const headers = { 'Content-Type': 'application/json', 'X-Request-Id': requestId };
  const opts = Object.assign({ method, headers }, options);
  const func = withAuth ? authFetch : fetchJSON;
  try {
    const fullUrl = url.startsWith('http') ? url : `${getBaseUrl()}${url}`;
    const resp = await func(fullUrl, opts);

    if (resp && resp.response.ok) {
      // we go back online when we get a successful non cached response, so if we get a 404, we don't go offline,
      // but we would also not return online until we receive a 20X.
      const respRequestId = resp.response.headers.get('X-Request-Id');
      if (respRequestId === requestId) {
        notifyOnline();
      }
      return resp;
    }
    throw new Error(getErrorDetail(resp));
  } catch (error) {
    // This catches network errors (TypeError), but also 4XX 5XX
    if (error instanceof TypeError) {
      notifyOffline();
    } else {
      const status = (error as any).status || 0;
      if (status === 502) {
        // we don't go offline for 4XX or 500.
        notifyOffline();
      }
    }
    throw new Error(getErrorDetail(error));
  }
};

export { makeRequest, getErrorDetail, retrieveToken, getLastOnlineCheck, setLastOnlineCheck };
